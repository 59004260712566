<template>
  <b-container>
    <section class="page-section">
      <b-row>
        <b-col>
          <div>
            <h1>404 Page Not Found</h1>
          </div>
        </b-col>
      </b-row>
    </section>
  </b-container>
</template>

<script>
export default {
  name: "NotFound"
};
</script>
